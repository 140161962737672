export default {
    dashboard: 'Dashboard',
    documentation: 'Documentação',
    park: 'Mapa do parque',
    register: 'Cadastros',
    profile: 'Perfil',
    indicators: 'Indicadores',
    route: {
      
    },
    tags: {
      new: 'NOVO!',
    },
    navbar: {
      search: 'Buscar...',
      noResults: 'Sem resultados.',
      searchFields:{
        pages: 'Páginas',
        files: 'Arquivos',
        contacts: 'Contatos'
      }
    },
    footer: {
      rights: 'Todos os direitos reservados.'
    },
    permission: {
      admin: 'Administrador',
      user: 'Usuário',
      root: 'Root'
    },
    sensors: {
      title: 'Sensores',
      printer: 'Impressora',
      device: 'Dispositivo',
      sensor: 'Sensor',
      machine: 'Máquina',
      general: 'Geral',
      validator: 'Validador',
      integration: 'Integração'
    },
    login:{
      welcome: 'Bem-vindo, faça login com sua conta.',
      forgotPassword: 'Esqueceu sua senha?',
      recoverPassword: 'Recupere sua senha',
      recoverInstructions: 'Por favor, insira seu e-mail e lhe enviaremos as instruções para resetar sua senha.',
      backLogin: 'Voltar ao Login',
      recover: 'Recuperar senha',
      rememberMe: 'Lembrar-me',
      enterNewPassword: 'Por favor insira sua nova senha',
      password: 'Senha',
      passwordConfirm: 'Confirmar senha',
      resetOkMessage: 'Sua senha foi alterada com sucesso',
      recoverOkMessage: 'Verifique sua caixa de email para instruções de recuperação de senha',
      redefinePassword: 'Redefinição de senha'
    },
    dialog: {
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      accept: 'Aceitar',
      error: 'Erro',
      success: 'Sucesso'
    },
    actions: {
      title: 'Ações',
      downloadFile: 'Fazer download do arquivo',
      backToHome: 'Voltar ao Dashboard',
      exportCSV: 'Exportar para CSV',
      exportXLS: 'Exportar para Excel',
      exportData: 'Exportar dados',
      export: 'Exportar',
      delete: 'Excluir',
      cancel: 'Cancelar',
      edit: 'Editar',
      add: 'Adicionar',
      reset: 'Resetar',
      save: 'Salvar',
      deActivate: 'Desativar',
      activate: 'Ativar',
      enterFilename: 'Nome do arquivo...'
    },
    pages: {
      errors: {
        notFound: '404 - Página não encontrada!',
        notAuthorized: 'Acesso não autorizado!',
      }
    },
    status: {
      active: 'Ativos',
      inactive: 'Inativos',
      status: 'Status',
      online: 'Online',
      offline: 'Offline',
      lastPing: 'Último ping:'
    },
    address: {
      line: 'Logradouro',
      zipcode: 'CEP',
      district: 'Bairro',
      city: 'Cidade',
      state: 'Estado',
      country: 'País'
    },
    common: {
      name: 'Nome',
      address: 'Endereço',
      phone: 'Telefone',
      registerData: 'Dados Cadastrais',
      company: 'Empresa',
      astec: 'ASTEC',
      id: 'Id',
      code: 'Código',
      info: 'Informações',
      register: 'Cadastro',
      contract: 'Contrato',
      files: 'Arquivos',
      description: 'Descrição',
      date: 'Data',
      all: 'Todas',
      type: 'Tipo',
      category: 'Categoria',
      message: 'Mensagem',
    },
    user: {
      users: 'Usuários',
      permission: 'Permissão',
      account: 'Conta',
      personal: 'Pessoal',
      dob: 'Data Nascimento',
      
      actions: {
        delete: 'Excluir usuário',
        new: 'Novo usuário',
        edit: 'Editar usuário',
        deActivate: 'Desativar usuário',
        activate: 'Ativar usuário',
        changeAvatar: 'Alterar Avatar',
        removeAvatar: 'Remover Avatar'
      },
      messages: {
        info: {
          passwordChange: 'Caso não queira alterar a senha, deixe estes campos em branco.'
        },
        success: {
          delete: 'Usuário excluído com sucesso',
          deActivate: 'Usuário marcado como inativo',
          activate: 'Usuário marcado como ativo',
          edit: 'Usuário atualizado com sucesso',
          create: 'Usuário criado com sucesso'
        },
        error: {
          delete: 'Erro ao excluir usuário',
          maxImageSize: 'Tamanho máximo da imagem é de 1MB!'
        },
        confirm: {
          delete: 'Está seguro que deseja excluir ',
          deActivate: 'Desativar o usuário ',
          activate: 'Ativar o usuário '
        }
      }
    },
    company: {
      companies: 'Entidades',
      trade: 'Nome fantasia',
      name: 'Razão social',
      entity: 'Entidade',
      employer_id: 'CNPJ',
      state_registration: 'Inscrição Estadual',
      email: 'Email de contato',
      contracts: 'Contratos',
      relationship: 'Vínculos',
      type: 'Tipo',
      actions: {
        new: 'Nova entidade',
        edit: 'Editar entidade',
        deActivate: 'Desativar entidade',
        activate: 'Ativar entidade',
        addRelation: 'Adicionar vínculo',
        removeRelation: 'Remover vínculo'
      },
      messages: {
        success: {
          deActivate: 'Entidade marcada como inativa',
          activate: 'Entidade marcada como ativa',
          edit: 'Entidade atualizada com sucesso',
          create: 'Entidade criada com sucesso',
          relationRemoved: 'Vínculo removido com sucesso',
          entityAdded: 'Entidade vinculada com sucesso'
        },
        error: {
          delete: 'Erro ao excluir Entidade',
        },
        confirm: {
          delete: 'Está seguro que quer excluir ',
          deActivate: 'Desativar a Entidade ',
          activate: 'Ativar a Entidade ',
          removeRelation: 'Tem certeza que deseja remover '
        }
  
      },
    },
    contract: {
      expiration: 'Validade',
      code: 'Código',
      plan: 'Plano contratado',
      dueDate: 'Data de cobrança',
      type: 'Tipo de contrato',
      fileType: 'Tipo de arquivo',
      values: {
        title: 'Valores',
        preventive: 'Preventiva',
        corrective: 'Corretiva',
        unproductive: 'Improdutiva',
        detached: 'Avulsa'
      },
      actions: {
        edit: 'Editar contrato',
        delete: 'Excluir contrato',
        deActivate: 'Desativar contrato',
        activate: 'Ativar contrato',
      },
      plans: {
        standard: 'Standard',
        premium: 'Premium',
        platinum: 'Platinum'
      },
      types: {
        fixed: 'Fixo',
        captation: 'Captação'
      },
      fileTypes: {
        main: 'Contrato',
        warranty: 'Garantia',
        ammends: 'Adicional'
      },
      messages: {
        success: {
          deActivate: 'Contrato marcado como inativo',
          activate: 'Contrato marcado como ativo',
          edit: 'Contrato atualizado com sucesso',
          create: 'Contrato criado com sucesso',
          fileUploaded: 'Arquivo enviado com sucesso'
        },
        error: {
          delete: 'Erro ao excluir empresa',
        },
        confirm: {
          delete: 'Está seguro que deseja excluir ',
          deActivate: 'Desativar o Contrato ',
          activate: 'Ativar o Contrato '
        },
        info: {
          saveBeforeFiles: 'Deve salvar o contrato antes de adicionar arquivos.'
        }
      },
    },
    machine: {
      machines: 'Máquinas',
      model: 'Modelo',
      product: 'Produto',
      location:{
        latitude: 'Latitude',
        longitude: 'Longitude'
      },
      serial_number: 'Serial',
      activationDate: 'Data de instalação',
      active: 'Ativa',
      company: 'Entidade associada',
      password: 'Senha de Integração',
      vault: {
        custodyAmount: 'Montante Custodiado',
        totalAutomatic: 'Total Dinheiro Automático',
        totalManual: 'Total Dinheiro Manual',
        totalCheck: 'Total Cheque',
        denominations: 'Denominações'
      },
      actions: {
        delete: 'Excluir máquina',
        new: 'Nova máquina',
        edit: 'Editar máquina',
        deActivate: 'Desativar máquina',
        activate: 'Ativar máquina'
      },
      messages: {
        info: {
        },
        success: {
          delete: 'Máquina excluída com sucesso',
          deActivate: 'Máquina marcada como inativo',
          activate: 'Máquina marcada como ativo',
          edit: 'Máquina atualizada com sucesso',
          create: 'Máquina criada com sucesso',
        },
        error: {
          delete: 'Erro ao excluir máquina'
        },
        confirm: {
          delete: 'Tem certeza que deseja excluir ',
          deActivate: 'Desativar a Máquina ',
          activate: 'Ativar a Máquina '
        }
      }
    },
  management: {
    management: 'Gerenciamento',
    logs: 'Registros',
    catamoedaStatus: 'Catamoeda',
  },
    transaction: {
      transactions: 'Transações',
      operation: 'Operação',
      company: 'Empresa',
      user: 'Usuário',
      login: 'Login',
      currency: 'Moeda',
      machine: 'Máquina',
      operationType: 'Tipo',
      operator: 'Nome do operador',
      value: 'Valor',
      balance: 'Balance',
      check: 'Cheque',
      money: 'Dinheiro',
      period: {
        title: 'Período',
        from: 'De',
        to: 'Até'
      },
      depositTypes: {
        auto: 'Automático',
        manual: 'Manual',
        productBonus: 'Vale-Compras',
        cashBonus: 'Troca por Notas',
        gift: 'Doação',
        internal: 'Transação Interna',
        promotion: 'Vale-Compras Promocional',
        cellPurchase: 'Recarga de Celular',
        googlePlayCredits: 'Google Play'
      },
      collectTypes: {
        forced: 'Forçada',
        default: 'Padrão'
      },
      depositFlows: {
        deposit: 'Depósito',
        withdrawal: 'Coleta',
        voucher: 'Cupom'
      },
      voucher : {
        reverse: 'Estornar',
        getData: 'Consultar',
        reverseVoucher: 'Consultar / Estornar cupom',
        reverseMsg: 'Entre com o código do cupom que você deseja consultar / estornar',
        voucherNumber: 'No. do Cupom',
        informUse: 'Informar utilização de cupons',
        useVoucher: 'Informar utilização',
        addVoucher: 'Adicionar cupom',
        typeVoucherNumber: 'Digite o número do cupom',
        validThru: 'Valido até',
        messages: {
          success: {
            voucherReverse: 'Cupom estornado com sucesso',
            voucherUsed: 'Cupom utilizados com sucesso'
          },
          error: {
            voucherAlreadyAdded: 'Cupom já adicionado',
            verify: 'Favor verifique os erros'
          }
        }
      },
    },
    group: {
      groups: 'Grupos de Acesso',
      actions: {
        delete: 'Excluir grupo',
        new: 'Novo grupo',
        edit: 'Editar grupo'
      },
      messages: {
        success: {
          delete: 'Grupo excluído com sucesso',
          edit: 'Grupo atualizado com sucesso',
          create: 'Grupo criado com sucesso',
        },
        error: {
          delete: 'Erro ao excluir grupo'
        },
        confirm: {
          delete: 'Tem certeza que deseja excluir ',
        }
      }
    },
    
  }
  