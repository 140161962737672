/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import { getPageTitle } from '@/utils/utils'
import i18n from '@/i18n/i18n'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [

        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/',
                    name: 'Dashboard',
                    component: () => import('./views/Dashboard.vue'),
                    meta: {
                        rule: 'editor',
                        requiresAuth: true,
                        pageTitle: 'Dashboard',
                        title: 'dashboard'
                    }
                },
                {
                    path: '/park',
                    name: 'park',
                    component: () => import('./views/Park.vue'),
                    meta: {
                        rule: 'editor',
                        breadcrumb: [
                            { title: 'dashboard', url: '/' },
                            { title: i18n.t('park'), active: true },
                        ],
                        pageTitle: i18n.t('park'),
                        title: 'park'
                    }
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import('./views/pages/user/profile/Profile.vue'),
                    meta: {
                        rule: 'editor',
                        breadcrumb: [
                            { title: 'dashboard', url: '/' },
                            { title: i18n.t('profile'), active: true },
                        ],
                        pageTitle: i18n.t('profile'),
                        title: 'profile'
                    }
                },
                {
                    path: '/indicators',
                    name: 'indicators',
                    component: () => import('./views/Indicators.vue'),
                    meta: {
                        rule: 'editor',
                        breadcrumb: [
                            { title: 'indicators', url: '/' },
                            { title: i18n.t('indicators'), active: true },
                        ],
                        pageTitle: i18n.t('indicators'),
                        title: 'indicators'
                    }
                },
                {
                    path: '/users',
                    component: () => import('./views/pages/NestedHome.vue'),
                    children: [
                        {
                            path: '/',
                            redirect: '/users/list'
                        },
                        {
                            path: '/users/list',
                            name: 'users-list',
                            component: () => import('./views/pages/user/UserList.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'users',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('user.users'), active: true },
                                ],
                                pageTitle: i18n.t('user.users'),
                                title: 'user.users'
                            },
                        },
                        {
                            path: '/users/edit',
                            name: 'users-edit',
                            component: () => import('@/views/pages/user/UserForm.vue'),
                            meta: {
                                parent: 'users',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('user.users'), url: '/users' },
                                    { title: i18n.t('user.actions.edit'), active: true },
                                ],
                                pageTitle: i18n.t('user.actions.edit'),
                                rule: 'editor'
                            }
                        },
                        {
                            path: '/users/new',
                            name: 'users-new',
                            component: () => import('@/views/pages/user/UserForm.vue'),
                            meta: {
                                parent: 'users',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('user.users'), url: '/users' },
                                    { title: i18n.t('user.actions.new'), active: true },
                                ],
                                pageTitle: i18n.t('user.actions.new'),
                                rule: 'editor'
                            }
                        },
                    ]

                },
                {
                    path: '/company',
                    component: () => import('./views/pages/NestedHome.vue'),
                    children: [
                        {
                            path: '/',
                            redirect: '/company/list'
                        },
                        {
                            path: '/company/list',
                            name: 'company-list',
                            component: () => import('./views/pages/company/CompanyList.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'company',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('company.companies'), active: true },
                                ],
                                pageTitle: i18n.t('company.companies'),
                                title: 'company.companies'
                            },
                        },
                        {
                            path: '/company/edit',
                            name: 'company-edit',
                            component: () => import('@/views/pages/company/CompanyForm.vue'),
                            meta: {
                                parent: 'company',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('company.companies'), url: '/company' },
                                    { title: i18n.t('company.actions.edit'), active: true },
                                ],
                                pageTitle: i18n.t('company.actions.edit'),
                                rule: 'editor'
                            }
                        },
                        {
                            path: '/company/new',
                            name: 'company-new',
                            component: () => import('@/views/pages/company/CompanyForm.vue'),
                            meta: {
                                parent: 'company',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('company.companies'), url: '/company' },
                                    { title: i18n.t('company.actions.new'), active: true },
                                ],
                                pageTitle: i18n.t('company.actions.new'),
                                rule: 'editor'
                            }
                        },
                    ]

                },
                {
                    path: '/machine',
                    component: () => import('./views/pages/NestedHome.vue'),
                    children: [
                        {
                            path: '/',
                            redirect: '/machine/list'
                        },
                        {
                            path: '/machine/list',
                            name: 'machine-list',
                            component: () => import('./views/pages/machine/MachineList.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'machine',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('machine.machines'), active: true },
                                ],
                                pageTitle: i18n.t('machine.machines'),
                                title: 'machine.machines'
                            },
                        },
                        {
                            path: '/machine/edit',
                            name: 'machine-edit',
                            component: () => import('@/views/pages/machine/MachineForm.vue'),
                            meta: {
                                parent: 'machine',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('machine.machines'), url: '/machine' },
                                    { title: i18n.t('machine.actions.edit'), active: true },
                                ],
                                pageTitle: i18n.t('machine.actions.edit'),
                                rule: 'editor'
                            }
                        },
                        {
                            path: '/machine/new',
                            name: 'machine-new',
                            component: () => import('@/views/pages/machine/MachineForm.vue'),
                            meta: {
                                parent: 'machine',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('machine.machines'), url: '/machine' },
                                    { title: i18n.t('machine.actions.new'), active: true },
                                ],
                                pageTitle: i18n.t('machine.actions.new'),
                                rule: 'editor'
                            }
                        },
                    ]

                },
                {
                    path: '/management',
                    component: () => import('./views/pages/NestedHome.vue'),
                    children: [
                        {
                            path: '/',
                            redirect: '/management/logs'
                        },
                        {
                            path: '/management/logs',
                            name: 'management-logs',
                            component: () => import('./views/pages/management/LogList.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'management',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('management.management') },
                                    { title: i18n.t('management.logs'), active: true },
                                ],
                                pageTitle: i18n.t('management.logs'),
                                title: 'management.logs'
                            },
                        },
                        {
                            path: '/management/catamoeda',
                            name: 'management-catamoeda',
                            component: () => import('./views/pages/management/CataMoedaStatus.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'management',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('management.management') },
                                    { title: 'CataMoeda', active: true },
                                ],
                                pageTitle: 'CataMoeda',
                                title: 'management.logs'
                            },
                        },
                    ]

                },
                {
                    path: '/transaction',
                    component: () => import('./views/pages/NestedHome.vue'),
                    children: [
                        {
                            path: '/',
                            redirect: '/transaction/deposit'
                        },
                        {
                            path: '/transaction/deposit',
                            name: 'transaction-deposit',
                            component: () => import('./views/pages/transaction/DepositList.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'transaction',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('transaction.transactions') },
                                    { title: i18n.t('transaction.depositFlows.deposit'), active: true },
                                ],
                                pageTitle: i18n.t('transaction.depositFlows.deposit'),
                                title: 'transaction.depositFlows.deposit'
                            },
                        },
                        {
                            path: '/transaction/withdrawal',
                            name: 'transaction-withdrawal',
                            component: () => import('./views/pages/transaction/WithdrawalList.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'transaction',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('transaction.transactions') },
                                    { title: i18n.t('transaction.depositFlows.withdrawal'), active: true },
                                ],
                                pageTitle: i18n.t('transaction.depositFlows.withdrawal'),
                                title: 'transaction.depositFlows.withdrawal'
                            },
                        },
                        {
                            path: '/transaction/voucher',
                            name: 'transaction-voucher',
                            component: () => import('./views/pages/transaction/Voucher.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'transaction',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('transaction.transactions') },
                                    { title: i18n.t('transaction.depositFlows.voucher'), active: true },
                                ],
                                pageTitle: i18n.t('transaction.depositFlows.voucher'),
                                title: 'transaction.depositFlows.vouchers'
                            },
                        },
                    ]

                },
                {
                    path: '/group',
                    component: () => import('./views/pages/NestedHome.vue'),
                    children:[
                        {
                            path: '/',
                            name: 'group-list',
                            component: () => import('./views/pages/group/GroupList.vue'),
                            meta: {
                                rule: 'editor',
                                parent: 'group',              
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('group.groups'), active: true },
                                ],
                                pageTitle: i18n.t('group.groups'),
                                title: 'group.groups'
                            },
                        },
                        {
                            path: '/group/create',
                            name: 'group-new',
                            component: () => import('@/views/pages/group/GroupForm.vue'),
                            meta: {
                                parent: 'group',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('group.groups'), url: '/group'},
                                    { title: i18n.t('group.actions.new'), active: true},
                                ],
                                pageTitle: i18n.t('group.actions.new'),
                                rule: 'editor'
                            }
                        },
                        {
                            path: '/group/:id',
                            name: 'group-edit',
                            component: () => import('@/views/pages/group/GroupForm.vue'),
                            meta: {
                                parent: 'group',
                                breadcrumb: [
                                    { title: 'dashboard', url: '/' },
                                    { title: i18n.t('register') },
                                    { title: i18n.t('group.groups'), url: '/group'},
                                    { title: i18n.t('group.actions.edit'), active: true},
                                ],
                                pageTitle: i18n.t('group.actions.edit'),
                                rule: 'editor'
                            }
                        },
                    ]
                    
                },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/callback',
                    name: 'auth-callback',
                    component: () => import('@/views/Callback.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/login',
                    name: 'page-login',
                    component: () => import('@/views/pages/login/Login.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/forgot-password',
                    name: 'page-forgot-password',
                    component: () => import('@/views/pages/ForgotPassword.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/reset-password/:id',
                    name: 'page-reset-password',
                    component: () => import('@/views/pages/ResetPassword.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/lock-screen',
                    name: 'page-lock-screen',
                    component: () => import('@/views/pages/LockScreen.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/error-500',
                    name: 'page-error-500',
                    component: () => import('@/views/pages/Error500.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/not-authorized',
                    name: 'page-not-authorized',
                    component: () => import('@/views/pages/NotAuthorized.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/maintenance',
                    name: 'page-maintenance',
                    component: () => import('@/views/pages/Maintenance.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }

})

router.beforeEach((to, from, next) => {
    document.title = getPageTitle(to.meta.title)

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('accessToken') == null) {
            next({
                path: '/pages/login'
            })
        } else {
            next()
        }
    }
    else {
        next()
    }
});

export default router
