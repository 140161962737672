/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import VueI18n from 'vue-i18n'
//import i18nData from './i18nData'

//import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
//import elementEsLocale from 'element-ui/lib/locale/lang/es'// element-ui lang
//import elementPtBrLocale from 'element-ui/lib/locale/lang/pt-br'// element-ui lang
import enLocale from './en'
import esLocale from './es'
import pt_BRLocale from './pt_BR'

import agGridEnLocale from './agGrid/en'
import agGridEsLocale from './agGrid/es'
import agGridPtBrLocale from './agGrid/pt_BR'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...agGridEnLocale,
   // ...elementEnLocale
  },
  es: {
    ...esLocale,
    ...agGridEsLocale,
   // ...elementEsLocale
  },
  pt_BR: {
    ...pt_BRLocale,
    ...agGridPtBrLocale,
   // ...elementPtBrLocale
  }
}

export function getLanguage() {
  const chooseLanguage = localStorage.getItem('language')
  if (chooseLanguage) return chooseLanguage
  
  return 'pt_BR'
}

export default new VueI18n({
  locale: getLanguage(), // set default locale
  messages,
})
