import axios from "../../../axios/index.js"
import router from "../../../../router.js"




export default {
  init() {

    axios.interceptors.request.use(
      config => {        
        config.headers['Authorization'] = "Bearer " + localStorage.getItem('accessToken');
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { response } = error

      // if (status === 401) {
      if (response && response.status === 401) {
        localStorage.removeItem("accessToken")
        router.push('/pages/login').catch(() => {})
      }
      return Promise.reject(error)
    })
  },
  login(email, pwd) {
    return axios.post("/auth/login", {login: email, password: pwd})
  },
  checkLoggedIn(){
    return axios.get("/auth/verify", {})
  }
}
