import i18n from '../../../i18n/i18n'

export default {
  pages: {
    key: "title",
    data: [
      // DASHBOARDS
      {title: 'Dashboard',   url: '/',      icon: 'HomeIcon', is_bookmarked: false},
      {title: i18n.t('park'), url: '/park', icon: 'MapIcon', is_bookmarked: false},
      {title: i18n.t('user.users'), url: '/users/list', icon: 'UsersIcon', is_bookmarked: false},
      {title: i18n.t('indicators'), url: '/indicators', icon: 'BarChart2Icon', is_bookmarked: false},
      {title: i18n.t('company.companies'), url: '/company/list', icon: 'BriefcaseIcon', is_bookmarked: false},
      {title: i18n.t('machine.machines'), url: '/machine/list', icon: 'PackageIcon', is_bookmarked: false},
      {title: i18n.t('transaction.transactions'), url: '/transaction', icon: 'DollarSignIcon', is_bookmarked: false},
      {title: i18n.t('transaction.depositFlows.deposit'), url: '/transaction/deposit', icon: 'ArrowRightIcon', is_bookmarked: false},
      {title: i18n.t('transaction.depositFlows.withdrawal'), url: '/transaction/withdrawal', icon: 'ArrowLeftIcon', is_bookmarked: false},
      {title: i18n.t('group.groups'), url: '/group/list', icon: 'UserCheckIcon', is_bookmarked: false},
    ],
  },
  files: {
    key:"file_name",
    data: [
      {file_name: "Contrato PROSEGUR", from:"Carlos Dutra", file_ext:"pdf", size:"1.7 mb" },
      
    ]
  },
  contacts: {
    key:"name",
    data: [
     // {img: require("@/assets/images/portrait/small/avatar-s-4.jpg"), name: "Rena Brant", email: "nephrod@preany.co.uk", time: "21/05/2019"},
     
    ]
  }
}
