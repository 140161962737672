export default {
  dashboard: 'Dashboard',
  documentation: 'Documentos',
  park: 'Mapa del parque',
  register: 'Catastros',
  profile: 'Perfil',
  indicators: 'Indicadores',
  route: {
    
  },
  tags: {
    new: 'NUEVO!',
  },
  navbar: {
    search: 'Buscar...',
    noResults: 'Sin resultados.',
    searchFields:{
      pages: 'Páginas',
      files: 'Archivos',
      contacts: 'Contactos'
    }
  },
  footer: {
    rights: 'Todos los derechos reservados.'
  },
  permission: {
    admin: 'Administrador',
    user: 'Usuario',
    root: 'Root'
  },
  sensors: {
    title: 'Sensores',
    printer: 'Impresora',
    device: 'Dispositivo',
    sensor: 'Sensor',
    machine: 'Máquina',
    general: 'General',
    validator: 'Validador',
    integration: 'Integración'
  },
  login:{
    welcome: 'Bienvenido, ingrese en su cuenta.',
    forgotPassword: 'Olvidó su contraseña?',
    recoverPassword: 'Recupere su contraseña',
    recoverInstructions: 'Por favor, ingrese su e-mail y le enviaremos las instrucciones para hacer reset de su contraseña.',
    backLogin: 'Volver al Login',
    recover: 'Recuperar contraseña',
    rememberMe: 'Recuerdeme',
    enterNewPassword: 'Por favor ingrese su contraseña nueva',
    password: 'Contraseña',
    passwordConfirm: 'Confirmar contraseña',
    resetOkMessage: 'Su contraseña fue alterada con éxito',
    recoverOkMessage: 'Verifique su buzón de correo para instrucciones de para recuperar su contraseña',
    redefinePassword: 'Redefinición de contraseña'
  },
  dialog: {
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    error: 'Error',
    success: 'Éxito'
  },
  actions: {
    title: 'Acciones',
    downloadFile: 'Realizar download del archivo',
    backToHome: 'Volver al Dashboard',
    exportCSV: 'Exportar para CSV',
    exportXLS: 'Exportar para Excel',
    exportData: 'Exportar datos',
    export: 'Exportar',
    delete: 'Excluir',
    cancel: 'Cancelar',
    edit: 'Editar',
    add: 'Adicionar',
    reset: 'Resetear',
    save: 'Guardar',
    deActivate: 'Desactivar',
    activate: 'Activar',
    enterFilename: 'Nombre del archivo...'
  },
  pages: {
    errors: {
      notFound: '404 - Página no encontrada!',
      notAuthorized: 'Acceso no autorizado!',
    }
  },
  status: {
    active: 'Activos',
    inactive: 'Inactivos',
    status: 'Status',
    online: 'Online',
    offline: 'Offline',
    lastPing: 'Último ping:'
  },
  address: {
    line: 'Dirección',
    zipcode: 'Código postal',
    district: 'Barrio',
    city: 'Ciudad',
    state: 'Estado/Provincia',
    country: 'País'
  },
  common: {
    name: 'Nombre',
    address: 'Dirección',
    phone: 'Teléfono',
    registerData: 'Datos Catastrales',
    company: 'Empresa',
    astec: 'ASTEC',
    id: 'Id',
    code: 'Código',
    info: 'Informaciones',
    register: 'Catastro',
    contract: 'Contrato',
    files: 'Archivos',
    description: 'Descripción',
    date: 'Fecha',
    all: 'Todas',
    type: 'Tipo',
    category: 'Categoría',
    message: 'Mensaje',
  },
  user: {
    users: 'Usuarios',
    permission: 'Permiso',
    account: 'Cuenta',
    personal: 'Personal',
    dob: 'Fecha de Nacimiento',
    
    actions: {
      delete: 'Excluir usuario',
      new: 'Nuevo usuario',
      edit: 'Editar usuario',
      deActivate: 'Desactivar usuario',
      activate: 'Activar usuario',
      changeAvatar: 'Modificar Avatar',
      removeAvatar: 'Remover Avatar'
    },
    messages: {
      info: {
        passwordChange: 'Si no desea modificar su contraseña, deje estos campos en blanco.'
      },
      success: {
        delete: 'Usuario excluído con éxito',
        deActivate: 'Usuario marcado como inactivo',
        activate: 'Usuario marcado como activo',
        edit: 'Usuario actualizado con éxito',
        create: 'Usuario creado com éxito'
      },
      error: {
        delete: 'Error ao excluir usuario',
        maxImageSize: 'Tamaño máximo de la imagen es de 1MB!'
      },
      confirm: {
        delete: 'Está seguro que desea excluir ',
        deActivate: 'Desactivar el usuario ',
        activate: 'Activar o usuario '
      }
    }
  },
  company: {
    companies: 'Entidades',
    trade: 'Nombre fantasia',
    name: 'Razón social',
    entity: 'Entidad',
    employer_id: 'RUT/RUC',
    state_registration: 'Inscripción Estadual',
    email: 'Email de contacto',
    contracts: 'Contratos',
    relationship: 'Vínculos',
    type: 'Tipo',
    actions: {
      new: 'Nueva entidad',
      edit: 'Editar entidad',
      deActivate: 'Desactivar entidad',
      activate: 'Activar entidad',
      addRelation: 'Adicionar vínculo',
      removeRelation: 'Remover vínculo'
    },
    messages: {
      success: {
        deActivate: 'Entidad marcada como inactiva',
        activate: 'Entidad marcada como activa',
        edit: 'Entidad actualizada con éxito',
        create: 'Entidad creada con éxito',
        relationRemoved: 'Vínculo removido con éxito',
        entityAdded: 'Entidad vinculada con éxito'
      },
      error: {
        delete: 'Error al excluir Entidad',
      },
      confirm: {
        delete: 'Está seguro que desea excluir ',
        deActivate: 'Desactivar la Entidad ',
        activate: 'Activar la Entidad ',
        removeRelation: 'Está seguro que desea remover '
      }

    },
  },
  contract: {
    expiration: 'Vencimiento',
    code: 'Código',
    plan: 'Plan contratado',
    dueDate: 'Fecha de cobro',
    type: 'Tipo de contrato',
    fileType: 'Tipo de archivo',
    values: {
      title: 'Valores',
      preventive: 'Preventiva',
      corrective: 'Correctiva',
      unproductive: 'Improductiva',
      detached: 'Avulsa'
    },
    actions: {
      edit: 'Editar contrato',
      delete: 'Excluir contrato',
      deActivate: 'Desactivar contrato',
      activate: 'Activar contrato',
    },
    plans: {
      standard: 'Standard',
      premium: 'Premium',
      platinum: 'Platinum'
    },
    types: {
      fixed: 'Fijo',
      captation: 'Captación'
    },
    fileTypes: {
      main: 'Contrato',
      warranty: 'Garantia',
      ammends: 'Adicional'
    },
    messages: {
      success: {
        deActivate: 'Contrato marcado como inactivo',
        activate: 'Contrato marcado como activo',
        edit: 'Contrato actualizado con éxito',
        create: 'Contrato creado con éxito',
        fileUploaded: 'Archivo enviado con éxito'
      },
      error: {
        delete: 'Error al excluir empresa',
      },
      confirm: {
        delete: 'Está seguro que desea excluir ',
        deActivate: 'Desactivar el Contrato ',
        activate: 'Activar el Contrato '
      },
      info: {
        saveBeforeFiles: 'Debe guardar el contrato antes de adicionar archivos.'
      }
    },
  },
  machine: {
    machines: 'Máquinas',
    model: 'Modelo',
    product: 'Producto',
    location:{
      latitude: 'Latitud',
      longitude: 'Longitud'
    },
    serial_number: 'Serial',
    activationDate: 'Fecha de instalación',
    active: 'Activa',
    company: 'Entidad asociada',
    password: 'Contraseña de Integración',
    vault: {
      custodyAmount: 'Montante Custodiado',
      totalAutomatic: 'Total Dinero Automático',
      totalManual: 'Total Dinero Manual',
      totalCheck: 'Total Cheque',
      denominations: 'Denominaciones'
    },
    actions: {
      delete: 'Excluir máquina',
      new: 'Nueva máquina',
      edit: 'Editar máquina',
      deActivate: 'Desactivar máquina',
      activate: 'Activar máquina'
    },
    messages: {
      info: {
      },
      success: {
        delete: 'Máquina excluída con éxito',
        deActivate: 'Máquina marcada como inactiva',
        activate: 'Máquina marcada como activa',
        edit: 'Máquina actualizada con éxito',
        create: 'Máquina creada con éxito'
      },
      error: {
        delete: 'Error al excluir máquina'
      },
      confirm: {
        delete: 'Está seguro que desea excluir ',
        deActivate: 'Desactivar la Máquina ',
        activate: 'Activar la Máquina '
      }
    }
  },
  management: {
    management: 'Gerenciamento',
    logs: 'Registros',
    catamoedaStatus: 'Catamoeda',
  },
  transaction: {
    transactions: 'Transacciones',
    operation: 'Operación',
    company: 'Empresa',
    user: 'Usuario',
    login: 'Login',
    currency: 'Moneda',
    machine: 'Máquina',
    operationType: 'Tipo',
    operator: 'Nombre del operador',
    value: 'Valor',
    balance: 'Balance',
    check: 'Cheque',
    money: 'Dinero',
    period: {
      title: 'Periodo',
      from: 'Desde',
      to: 'Hasta'
    },
    depositTypes: {
      auto: 'Automatico',
      manual: 'Manual',
      productBonus: 'Vale-Compras',
      cashBonus: 'Cambio por Billetes',
      gift: 'Donación',
      internal: 'Transacción Interna',
      promotion: 'Vale-Compras Promocional',
      cellPurchase: 'Recarga de Celular',
    },
    collectTypes: {
      forced: 'Forzada',
      default: 'Común'
    },
    depositFlows: {
      deposit: 'Depósito',
      withdrawal: 'Retirada',
      voucher: 'Cupón'
    },
    voucher : {
      reverse: 'Reactivar',
      getData: 'Consultar',
      reverseVoucher: 'Consultar / Reactivar cupón',
      reverseMsg: 'Entre con el código del cupón que desea consultar / reactivar',
      voucherNumber: 'No. del Cupón',
      informUse: 'Informar uso de cupón',
      useVoucher: 'Informar uso',
      addVoucher: 'Adicionar cupón',
      typeVoucherNumber: 'Digite el número del cupón',
      validThru: 'Valido hasta',
      messages: {
        success: {
          voucherReverse: 'Cupón reactivado con éxito',
          voucherUsed: 'Cupón utilizados con éxito'
        },
        error: {
          voucherAlreadyAdded: 'Cupón ya adicionado',
          verify: 'Favor verifique los errores'
        }
      }
    }
  },
  group: {
    groups: 'Grupos de Acceso',
    actions: {
      delete: 'Excluir grupo',
      new: 'Nuevo grupo',
      edit: 'Editar grupo'
    },
    messages: {
      success: {
        delete: 'Grupo excluído con éxito',
        edit: 'Grupo actualizado con éxito',
        create: 'Grupo creado con éxito',
      },
      error: {
        delete: 'Error al excluir grupo'
      },
      confirm: {
        delete: 'Está seguro que desea excluir ',
      }
    }
  },
  
}
