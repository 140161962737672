export default {
  dashboard: 'Dashboard',
  documentation: 'Documentation',
  park: 'Map of the park',
  register: 'Register',
  profile: 'Profile',
  indicators: 'Indicators',
  route: {
    
  },
  tags: {
    new: 'NEW!',
  },
  navbar: {
    search: 'Search...',
    noResults: 'No results.',
    searchFields:{
      pages: 'Pages',
      files: 'Files',
      contacts: 'Contacts'
    }
  },
  footer: {
    rights: 'All rights reserved.'
  },
  permission: {
    admin: 'Admin',
    user: 'User',
    root: 'Root'
  },
  sensors: {
    title: 'Sensors',
    printer: 'Printer',
    device: 'Device',
    sensor: 'Sensor',
    machine: 'Machine',
    general: 'General',
    validator: 'Validator',
    integration: 'Integration'
  },
  login:{
    welcome: 'Welcome back, please login to your account.',
    forgotPassword: 'Forgot Password?',
    recoverPassword: 'Recover your password',
    recoverInstructions: 'Please enter your email address and we\'ll send you instructions on how to reset your password.',
    backLogin: 'Return to Login',
    recover: 'Recover password',
    rememberMe: 'Remember me',
    enterNewPassword: 'Please enter your new password',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    resetOkMessage: 'Your password was changed successfully',
    recoverOkMessage: 'Check your email box for instructions on how to recover your password',
    redefinePassword: 'Password recovery'
  },
  dialog: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    accept: 'OK',
    error: 'Error',
    success: 'Success'
  },
  actions: {
    title: 'Actions',
    downloadFile: 'Download file',
    backToHome: 'Return to Dashboard',
    exportCSV: 'Export to CSV',
    exportXLS: 'Export to Excel',
    exportData: 'Export data',
    export: 'Export',
    delete: 'Delete',
    cancel: 'Cancel',
    edit: 'Edit',
    add: 'Add',
    reset: 'Reset',
    save: 'Save',
    deActivate: 'Deactivate',
    activate: 'Activate',
    enterFilename: 'Filename...'
  },
  pages: {
    errors: {
      notFound: '404 - Page not found!',
      notAuthorized: 'Unauthorized access!',
    }
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
    status: 'Status',
    online: 'Online',
    offline: 'Offline',
    lastPing: 'Last ping:'
  },
  address: {
    line: 'Address',
    zipcode: 'ZIP code',
    district: 'District',
    city: 'City',
    state: 'State',
    country: 'Country'
  },
  common: {
    name: 'Name',
    address: 'Address',
    phone: 'Phone',
    registerData: 'Register Data',
    company: 'Company',
    astec: 'ASTEC',
    id: 'Id',
    code: 'Code',
    info: 'Information',
    register: 'Register',
    contract: 'Contract',
    files: 'Files',
    description: 'Description',
    date: 'Date',
    all: 'All',
    type: 'Type',
    category: 'Category',
    message: 'Message',
  },
  user: {
    users: 'Users',
    permission: 'Permission',
    account: 'Account',
    personal: 'Personal',
    dob: 'Date of Birth',
    
    actions: {
      delete: 'Delete user',
      new: 'New user',
      edit: 'Edit user',
      deActivate: 'Deactivate user',
      activate: 'Activate user',
      changeAvatar: 'Change Avatar',
      removeAvatar: 'Remove Avatar'
    },
    messages: {
      info: {
        passwordChange: `If you don't want to change your password, leave this fields blank.`
      },
      success: {
        delete: 'User deleted successfully',
        deActivate: 'User marked as inactive',
        activate: 'User marked as active',
        edit: 'User updated successfully',
        create: 'User created successfully'
      },
      error: {
        delete: 'Error deleting user',
        maxImageSize: 'Max file size is 1MB!'
      },
      confirm: {
        delete: 'Are you sure you want to delete ',
        deActivate: 'Deactivate user ',
        activate: 'Activate user '
      }
    }
  },
  company: {
    companies: 'Entities',
    trade: 'Trade',
    name: 'Name',
    entity: 'Entity',
    employer_id: 'Employer ID',
    state_registration: 'State registration',
    email: 'Email',
    contracts: 'Contracts',
    relationship: 'Relationship',
    type: 'Type',
    actions: {
      new: 'New entity',
      edit: 'Edit entity',
      deActivate: 'Deactivate entity',
      activate: 'Activate entity',
      addRelation: 'Add relation',
      removeRelation: 'Remove relation'
    },
    messages: {
      success: {
        deActivate: 'Entity marked as inactive',
        activate: 'Entity marked as active',
        edit: 'Entity updated successfully',
        create: 'Entity created successfully',
        relationRemoved: 'Relation removed successfully',
        entityAdded: 'Entity added successfully'
      },
      error: {
        delete: 'Error deleting entity',
      },
      confirm: {
        delete: 'Are you sure you want to delete ',
        deActivate: 'Deactivate Entity ',
        activate: 'Activate Entity ',
        removeRelation: 'Are you sure you want to remove '
      }

    },
  },
  contract: {
    expiration: 'Expiration',
    code: 'Code',
    plan: 'Plan hired',
    dueDate: 'Due date',
    type: 'Contract Type',
    fileType: 'File Type',
    values: {
      title: 'Values',
      preventive: 'Preventive',
      corrective: 'Corrective',
      unproductive: 'Improductive',
      detached: 'Detached'
    },
    actions: {
      edit: 'Edit contract',
      delete: 'Delete contract',
      deActivate: 'Deactivate contract',
      activate: 'Activate contract',
    },
    plans: {
      standard: 'Standard',
      premium: 'Premium',
      platinum: 'Platinum'
    },
    types: {
      fixed: 'Fixed',
      captation: 'Captation'
    },
    fileTypes: {
      main: 'Contract',
      warranty: 'Warranty',
      ammends: 'Ammends'
    },
    messages: {
      success: {
        deActivate: 'Contract marked as inactive',
        activate: 'Contract marked as active',
        edit: 'Contract updated successfully',
        create: 'Contract created successfully',
        fileUploaded: 'File uploaded successfully'
      },
      error: {
        delete: 'Error deleting company',
      },
      confirm: {
        delete: 'Are you sure you want to delete ',
        deActivate: 'Deactivate contract ',
        activate: 'Activate contract '
      },
      info: {
        saveBeforeFiles: 'You must first save the contract before adding files.'
      }
    },
  },
  machine: {
    machines: 'Machines',
    model: 'Model',
    product: 'Product',
    location:{
      latitude: 'Latitude',
      longitude: 'Longitude'
    },
    serial_number: 'Serial Number',
    activationDate: 'Installation Date',
    active: 'Active',
    company: 'Associated Entity',
    password: 'Integration Password',
    vault: {
      custodyAmount: 'Custody Amount',
      totalAutomatic: 'Total Automatic',
      totalManual: 'Total Manual',
      totalCheck: 'Total Check',
      denominations: 'Denominations'
    },
    actions: {
      delete: 'Delete machine',
      new: 'New machine',
      edit: 'Edit machine',
      deActivate: 'Deactivate machine',
      activate: 'Activate machine'
    },
    messages: {
      info: {
      },
      success: {
        delete: 'Machine deleted successfully',
        deActivate: 'Machine marked as inactive',
        activate: 'Machine marked as active',
        edit: 'Machine updated successfully',
        create: 'Machine created successfully'
      },
      error: {
        delete: 'Error deleting machine'
      },
      confirm: {
        delete: 'Are you sure you want to delete ',
        deActivate: 'Deactivate Machine ',
        activate: 'Activate Machine '
      }
    }
  },
  management: {
    management: 'Management',
    logs: 'Logs',
    catamoedaStatus: 'Catamoeda',
  },
  transaction: {
    transactions: 'Transactions',
    operation: 'Operation',
    company: 'Company',
    user: 'User',
    login: 'Login',
    currency: 'Currency',
    machine: 'Machine',
    operationType: 'Type',
    operator: 'Operator name',
    value: 'Value',
    balance: 'Balance',
    check: 'Check',
    money: 'Money',
    period: {
      title: 'Period',
      from: 'From',
      to: 'To'
    },
    depositTypes: {
      auto: 'Automatic',
      manual: 'Manual',
      productBonus: 'Product Bonus',
      cashBonus: 'Cash Bonus',
      gift: 'Donation',
      internal: 'Internal Transaction',
      promotion: 'Promo Product Bonus',
      cellPurchase: 'Cell Phone Credit',
    },
    collectTypes: {
      forced: 'Forced',
      default: 'Default'
    },
    depositFlows: {
      deposit: 'Deposit',
      withdrawal: 'Withdrawal',
      voucher: 'Voucher'
    },
    voucher : {
      reverse: 'Reverse',
      getData: 'Verify',
      reverseVoucher: 'Verify / Reverse voucher',
      reverseMsg: 'Type the voucher number you want to verify / reverse',
      voucherNumber: 'Voucher Number',
      informUse: 'Inform voucher use',
      useVoucher: 'Inform use',
      addVoucher: 'Add Voucher',
      typeVoucherNumber: 'Type the voucher number',
      validThru: 'Valid thru',
      messages: {
        success: {
          voucherReverse: 'Voucher reversed',
          voucherUsed: 'Voucher used'
        },
        error: {
          voucherAlreadyAdded: 'Voucher already added',
          verify: 'Please verify errors'
        }
      }
    }
  },
  group: {
    groups: 'Access Groups',
    actions: {
      delete: 'Delete group',
      new: 'New group',
      edit: 'Edit group'
    },
    messages: {
      success: {
        delete: 'Group deleted successfully',
        edit: 'Group updated successfully',
        create: 'Group created successfully',
      },
      error: {
        delete: 'Error deleting group'
      },
      confirm: {
        delete: 'Are you sure you want to delete ',
      }
    }
  },
  
}
