/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from 'axios'
import router from "@/router.js"

const request = function(commit, method, url, data) {
  
  return axios({
    baseURL: process.env.VUE_APP_ENDPOINT,
    url,
    method,
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => {
      const response = res.data;
      if (res.config.method !== 'get') {
        if (!response.success) {
          throw new Error(response.error);
        }
      }
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("accessToken")
        router.push('/pages/login').catch(() => {})
      }

      throw new Error(error);
    });
  
} 

const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
    updateUserRole({ dispatch }, payload) {
      // Change client side
      payload.aclChangeRole(payload.userRole)

      // Make API call to server for changing role

      // Change userInfo in localStorage and store
      dispatch('updateUserInfo', {userRole: payload.userRole})
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },

    httpRequest({ commit }, params) {
      return request(commit, params.method, params.url, params.data);
    },
    
    uploadFileToServer(commit, params) {

      return axios({
        baseURL: process.env.VUE_APP_ENDPOINT,
        url: params.url,
        method: 'post',
        data: params.data,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
      }).then(function(res){
        const response = res.data;
        return response;
      })
      .catch(function(error){
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("accessToken")
          router.push('/pages/login').catch(() => {})
        }
  
        throw new Error(error.response.data.error);
      });
    }
}

export default actions
