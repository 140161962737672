import i18n from '../i18n/i18n'
import router from '../router.js'

const title = 'CataCloud'

export function getPageTitle(key) {
  const hasKey = i18n.te(`${key}`)
  
  if (hasKey) {
    const pageName = i18n.t(`${key}`)
    return `${pageName} - ${title}`
  }
  return `${title}`
}

export function updateTitle() {
    document.title = getPageTitle(router.currentRoute.meta.title)
}

export function getAgGridTranslation(key, defaultValue) {
  var gridKey = 'agGrid.' + key;

  var value = i18n.t(gridKey);
  return value == gridKey ? defaultValue : value;
}